.condiv {
    position: relative;
    display: flex;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    z-index: 1031;
}

.overlay {
    position: relative;
    display: flex;
    width: 100%;
    height: 100vh;
    left: 0;
    z-index: 1031;
    background-color: #111;
}

.loading_text {
    position: absolute;
    width: 100%;
    color: #fff;
    font-weight: 500;
    z-index: 2;
    width: 100%;
    top: 47vh;
}

.loadingbar {
    position: absolute;
    display: flex;
    width: 100%;
    height: 1px;
    background-color: #fff;
    z-index: 2;
    width: 100%;
    top: 50vh;
    transform: translateX(-100%);
}

.movediv01 {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 50vh;
    /* opacity: 0; */
    background-color: #0D0D0D;
    z-index: 1;
}

.movediv02 {
    position: absolute;
    display: flex;
    top: 50vh;
    left: 0;
    width: 100%;
    height: 50vh;
    /* opacity: 0; */
    background-color: #0D0D0D;
    z-index: 1;
}