.logo {
    position: fixed;
    top: 1rem;
    width: 4rem;
    height: 4rem;
    display: flex;
    align-items: center;
    z-index: 100001;
}

.logo-svg {
    width: 3rem;
    height: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    overflow: hidden;
}

.menu {
    position: absolute;
    right: 10px;
    top: 0.8rem;
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 100001;
}

.menu-line-wrapper {
    width: 2rem;
    height: 1.5rem;
    margin-left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    overflow: hidden;
    z-index: 1000;
}

.menu-line-wrapper:hover .menu-line-2 {
    width: 70%;
}

.menu-line {
    width: 100%;
    height: 0.2rem;
    background-color: #111;
    transform-origin: center;
    transition: width 0.1s;
}

nav {
    position: absolute;
    display: none;
    align-items: center;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100vh);
    z-index: 1001;
    background-color: #111;
}

nav .nav-menu {
    position: absolute;
}

nav .nav-menu .menu-text {
    color: #fff;
    cursor: pointer;
}

.menu-link-1,
.menu-link-2,
.menu-link-3,
.menu-link-4,
.menu-link-5 {
    position: relative;
    opacity: 0;
    transform: translateX(-150%);
    cursor: default;
}

.mainBg {
    background-color: #111;
}

.subBg {
    background: rgb(255, 255, 255, 1);
}