.motion-default-container {
    position: relative;
    display: flex;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    z-index: 1000010;
}

.overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0%;
    z-index: 2;
    background: #ec1417;
}

.first {
    background: #8C8C8C;
}

.second {
    background: #595959;
    left: 20%;
}

.third {
    background: #404040;
    left: 40%;

}

.fourth {
    background: #262626;
    left: 60%;
}

.fifth {
    background: #0D0D0D;
    left: 80%;
}