@charset "utf-8";
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 100;
    src: url("../fonts/NotoSansKR-Thin.woff2") format('woff2'),
        url("../fonts/NotoSansKR-Thin.woff") format('woff'),
        url("../fonts/NotoSansKR-Thin.otf") format('opentype');
}

@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 300;
    src: url("../fonts/NotoSansKR-Light.woff2") format('woff2'),
        url("../fonts/NotoSansKR-Light.woff") format('woff'),
        url("../fonts/NotoSansKR-Light.otf") format('opentype');
}

@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/NotoSansKR-Regular.woff2") format('woff2'),
        url("../fonts/NotoSansKR-Regular.woff") format('woff'),
        url("../fonts/NotoSansKR-Regular.otf") format('opentype');
}

@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 500;
    src: url("../fonts/NotoSansKR-Medium.woff2") format('woff2'),
        url("../fonts/NotoSansKR-Medium.woff") format('woff'),
        url("../fonts/NotoSansKR-Medium.otf") format('opentype');
}

@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 700;
    src: url("../fonts/NotoSansKR-Bold.woff2") format('woff2'),
        url("../fonts/NotoSansKR-Bold.woff") format('woff'),
        url("../fonts/NotoSansKR-Bold.otf") format('opentype');
}

@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 900;
    src: url("../fonts/NotoSansKR-Black.woff2") format('woff2'),
        url("../fonts/NotoSansKR-Black.woff") format('woff'),
        url("../fonts/NotoSansKR-Black.otf") format('opentype');
}


html,
body {
    height: 100%;
    max-width: 100%;
    margin: 0;
    padding: 0;
    overflow-y: auto;
    overflow-x: hidden;
    /* mobile viewport bug fix */
    min-height: -webkit-fill-available;
    -webkit-overflow-scrolling: touch;
}

body {
    font-family: 'Roboto', 'Noto Sans KR';
    font-size: 16px;
    background-color: #111;
}

.roboto {
    font-family: 'Roboto';
}

/* padding margin class*/

.dis_box {
    display: inline-block;
}

/*
label { color:#111; font-weight:100; }
*/
.pz {
    padding: 0;
}

.mz {
    margin: 0;
}

.mar_cen {
    margin: auto;
}


ul,
li {
    list-style: none;
    text-decoration: none;
    padding: 0;
    border: 0;
}

.over_h {
    overflow: hidden;
}

.cp {
    cursor: pointer;
}

.dis_n {
    display: none;
}

.dis_b {
    display: block;
}

.cl_b {
    clear: both;
}

.fl_r {
    float: right;
}

.fl_l {
    float: left;
}

.text_j {
    text-align: justify;
}

.text_del {
    text-decoration: line-through;
}

.text_line {
    text-decoration: underline;
}

.text_g {
    font-style: oblique;
}

a:link {
    text-decoration: none;
    color: #111;
}

a:hover {
    text-decoration: none;
    color: #111;
}

a:visited {
    color: #111;
}

.text_de {
    text-shadow: 1px 1px 1px gray;
}

/* border */
.bo1 {
    border: 1px solid red;
}

/* margin */
.m10p {
    margin-top: 10%;
}

.m10 {
    margin: 10px;
}

.m15 {
    margin: 15px;
}

.m20 {
    margin: 20px;
}


.mtm2 {
    margin-top: -2px;
}

.mtm3 {
    margin-top: -3px;
}

.mtm4 {
    margin-top: -4px;
}

.mtm5 {
    margin-top: -5px;
}

.mtm8 {
    margin-top: -8px;
}

.mt1 {
    margin-top: 1px;
}

.mt2 {
    margin-top: 2px;
}

.mt3 {
    margin-top: 3px;
}

.mt4 {
    margin-top: 4px;
}

.mt5 {
    margin-top: 5px;
}

.mt6 {
    margin-top: 6px;
}

.mt7 {
    margin-top: 7px;
}

.mt10 {
    margin-top: 10px;
}

.mt12 {
    margin-top: 12px;
}

.mt13 {
    margin-top: 13px;
}

.mt15 {
    margin-top: 15px;
}

.mt17 {
    margin-top: 17px;
}

.mt18 {
    margin-top: 18px;
}

.mt20 {
    margin-top: 20px;
}

.mt25 {
    margin-top: 25px;
}

.mt26 {
    margin-top: 26px;
}

.mt30 {
    margin-top: 30px;
}

.mt32 {
    margin-top: 32px;
}

.mt33 {
    margin-top: 33px;
}

.mt34 {
    margin-top: 34px;
}

.mt35 {
    margin-top: 35px;
}

.mt37 {
    margin-top: 37px;
}

.mt40 {
    margin-top: 40px;
}

.mt43 {
    margin-top: 43px;
}

.mt44 {
    margin-top: 44px;
}

.mt45 {
    margin-top: 45px;
}

.mt46 {
    margin-top: 46px;
}

.mt47 {
    margin-top: 47px;
}

.mt50 {
    margin-top: 50px;
}

.mt54 {
    margin-top: 54px;
}

.mt55 {
    margin-top: 55px;
}

.mt60 {
    margin-top: 60px;
}

.mt66 {
    margin-top: 66px;
}

.mt70 {
    margin-top: 70px;
}

.mt77 {
    margin-top: 77px;
}

.mt75 {
    margin-top: 75px;
}

.mt80 {
    margin-top: 80px;
}

.mt85 {
    margin-top: 85px;
}

.mt90 {
    margin-top: 90px;
}

.mt95 {
    margin-top: 95px;
}

.mt100 {
    margin-top: 100px;
}

.mt110 {
    margin-top: 110px;
}

.mt114 {
    margin-top: 114px;
}

.mt120 {
    margin-top: 120px;
}

.mt130 {
    margin-top: 130px;
}

.mt135 {
    margin-top: 135px;
}

.mt140 {
    margin-top: 140px;
}

.mt150 {
    margin-top: 150px;
}

.mt160 {
    margin-top: 160px;
}

.mt170 {
    margin-top: 170px;
}

.mt190 {
    margin-top: 190px;
}

.mt200 {
    margin-top: 200px;
}

.mt207 {
    margin-top: 207px;
}

.mt220 {
    margin-top: 220px;
}

.mt230 {
    margin-top: 230px;
}

.mt250 {
    margin-top: 250px;
}

.mt350 {
    margin-top: 350px;
}

.mt400 {
    margin-top: 400px;
}

.mt420 {
    margin-top: 420px;
}

.mt430 {
    margin-top: 430px;
}

.mb2 {
    margin-bottom: 2px;
}

.mb5 {
    margin-bottom: 5px;
}

.mb6 {
    margin-bottom: 6px;
}

.mb10 {
    margin-bottom: 10px;
}

.mb15 {
    margin-bottom: 15px;
}

.mb20 {
    margin-bottom: 20px;
}

.mb25 {
    margin-bottom: 25px;
}

.mb30 {
    margin-bottom: 30px;
}

.mb40 {
    margin-bottom: 40px;
}

.mb50 {
    margin-bottom: 50px;
}

.mb60 {
    margin-bottom: 60px;
}

.mb70 {
    margin-bottom: 70px;
}

.mb77 {
    margin-bottom: 77px;
}

.mb80 {
    margin-bottom: 80px;
}

.mb90 {
    margin-bottom: 90px;
}

.mb100 {
    margin-bottom: 100px;
}

.mb120 {
    margin-bottom: 120px;
}

.mb130 {
    margin-bottom: 130px;
}

.mb140 {
    margin-bottom: 140px;
}

.mb150 {
    margin-bottom: 150px;
}

.mb170 {
    margin-bottom: 170px;
}

.mb200 {
    margin-bottom: 200px;
}

.ml5 {
    margin-left: 5px;
}

.ml7 {
    margin-left: 7px;
}

.ml10 {
    margin-left: 10px;
}

.ml15 {
    margin-left: 15px;
}

.ml17 {
    margin-left: 17px;
}

.ml20 {
    margin-left: 20px;
}

.ml25 {
    margin-left: 25px;
}

.ml30 {
    margin-left: 30px;
}

.ml40 {
    margin-left: 40px;
}

.ml50 {
    margin-left: 50px;
}

.ml60 {
    margin-left: 60px;
}

.ml70 {
    margin-left: 70px;
}

.ml150 {
    margin-left: 150px;
}

.ml220 {
    margin-left: 220px;
}

.mr5 {
    margin-right: 5px;
}

.mr10 {
    margin-right: 10px;
}

.mr13 {
    margin-right: 13px;
}

.mr14 {
    margin-right: 14px;
}

.mr15 {
    margin-right: 15px;
}

.mr17 {
    margin-right: 17px;
}

.mr20 {
    margin-right: 20px;
}

.mr25 {
    margin-right: 25px;
}

.mr27 {
    margin-right: 27px;
}

.mr30 {
    margin-right: 30px;
}

.mr35 {
    margin-right: 35px;
}

.mr40 {
    margin-right: 40px;
}

.mr50 {
    margin-right: 50px;
}

.mr60 {
    margin-right: 60px;
}

.mr70 {
    margin-right: 70px;
}

/* padding */
.p1 {
    padding: 1px;
}

.p2 {
    padding: 2px;
}

.p3 {
    padding: 3px;
}

.p3 {
    padding: 4px;
}

.p5 {
    padding: 5px;
}

.p10 {
    padding: 10px;
}

.p14 {
    padding: 14px;
}

.p15 {
    padding: 15px;
}

.p20 {
    padding: 20px;
}

.p25 {
    padding: 25px;
}

.p30 {
    padding: 30px;
}

.p35 {
    padding: 35px;
}

.p40 {
    padding: 40px;
}

.p50 {
    padding: 50px;
}

.p90 {
    padding: 90px;
}

.pt1 {
    padding-top: 1px;
}

.pt2 {
    padding-top: 2px;
}

.pt3 {
    padding-top: 3px;
}

.pt4 {
    padding-top: 4px;
}

.pt5 {
    padding-top: 5px;
}

.pt6 {
    padding-top: 6px;
}

.pt7 {
    padding-top: 7px;
}

.pt8 {
    padding-top: 8px;
}

.pt9 {
    padding-top: 9px;
}

.pt10 {
    padding-top: 10px;
}

.pt12 {
    padding-top: 12px;
}

.pt14 {
    padding-top: 14px;
}

.pt15 {
    padding-top: 15px;
}

.pt16 {
    padding-top: 16px;
}

.pt17 {
    padding-top: 17px;
}

.pt18 {
    padding-top: 18px;
}

.pt20 {
    padding-top: 20px;
}

.pt21 {
    padding-top: 21px;
}

.pt23 {
    padding-top: 23px;
}

.pt13 {
    padding-top: 13px;
}

.pt20 {
    padding-top: 20px;
}

.pt25 {
    padding-top: 25px;
}

.pt28 {
    padding-top: 28px;
}

.pt30 {
    padding-top: 30px;
}

.pt35 {
    padding-top: 35px;
}

.pt40 {
    padding-top: 40px;
}

.pt43 {
    padding-top: 43px;
}

.pt45 {
    padding-top: 45px;
}

.pt50 {
    padding-top: 50px;
}

.pt55 {
    padding-top: 55px;
}

.pt60 {
    padding-top: 60px;
}

.pt65 {
    padding-top: 65px;
}

.pt70 {
    padding-top: 70px;
}

.pt80 {
    padding-top: 80px;
}

.pt90 {
    padding-top: 90px;
}

.pt100 {
    padding-top: 100px;
}

.pt110 {
    padding-top: 110px;
}

.pt135 {
    padding-top: 135px;
}

.pt140 {
    padding-top: 140px;
}

.pt170 {
    padding-top: 170px;
}

.pt180 {
    padding-top: 180px;
}

.pt300 {
    padding-top: 300px;
}

.pb2 {
    padding-bottom: 2px;
}

.pb3 {
    padding-bottom: 3px;
}

.pb5 {
    padding-bottom: 5px;
}

.pb7 {
    padding-bottom: 7px;
}

.pb8 {
    padding-bottom: 8px;
}

.pb9 {
    padding-bottom: 9px;
}

.pb10 {
    padding-bottom: 10px;
}

.pb14 {
    padding-bottom: 14px;
}

.pb15 {
    padding-bottom: 15px;
}

.pb18 {
    padding-bottom: 18px;
}

.pb20 {
    padding-bottom: 20px;
}

.pb21 {
    padding-bottom: 21px;
}

.pb24 {
    padding-bottom: 24px;
}

.pb25 {
    padding-bottom: 25px;
}

.pb30 {
    padding-bottom: 30px;
}

.pb37 {
    padding-bottom: 37px;
}

.pb40 {
    padding-bottom: 40px;
}

.pb50 {
    padding-bottom: 50px;
}

.pb60 {
    padding-bottom: 60px;
}

.pb70 {
    padding-bottom: 70px;
}

.pb75 {
    padding-bottom: 75px;
}

.pb80 {
    padding-bottom: 80px;
}

.pb90 {
    padding-bottom: 90px;
}

.pb100 {
    padding-bottom: 100px;
}

.pb110 {
    padding-bottom: 110px;
}

.pb300 {
    padding-bottom: 300px;
}

.pl1 {
    padding-left: 1px;
}

.pl2 {
    padding-left: 2px;
}

.pl3 {
    padding-left: 3px;
}

.pl4 {
    padding-left: 4px;
}

.pl5 {
    padding-left: 5px;
}

.pl10 {
    padding-left: 10px;
}

.pl10p {
    padding-left: 10%;
}

.pl11 {
    padding-left: 11px;
}

.pl14 {
    padding-left: 14px;
}

.pl15 {
    padding-left: 15px;
}

.pl16 {
    padding-left: 16px;
}

.pl17 {
    padding-left: 17px;
}

.pl19 {
    padding-left: 19px;
}

.pl20 {
    padding-left: 20px;
}

.pl25 {
    padding-left: 25px;
}

.pl30 {
    padding-left: 30px;
}

.pl35 {
    padding-left: 35px;
}

.pl40 {
    padding-left: 40px;
}

.pl45 {
    padding-left: 45px;
}

.pl50 {
    padding-left: 50px;
}

.pl60 {
    padding-left: 60px;
}

.pl70 {
    padding-left: 70px;
}

.pl80 {
    padding-left: 80px;
}

.pl85 {
    padding-left: 85px;
}

.pl90 {
    padding-left: 90px;
}

.pl95 {
    padding-left: 95px;
}

.pl55 {
    padding-left: 55px;
}

.pl100 {
    padding-left: 100px;
}

.pl110 {
    padding-left: 110px;
}

.pl120 {
    padding-left: 120px;
}

.pl130 {
    padding-left: 130px;
}

.pl150 {
    padding-left: 150px;
}

.pr1 {
    padding-right: 1px;
}

.pr5 {
    padding-right: 5px;
}

.pr10 {
    padding-right: 10px;
}

.pr11 {
    padding-right: 11px;
}

.pr14 {
    padding-right: 14px;
}

.pr15 {
    padding-right: 15px;
}

.pr16 {
    padding-right: 16px;
}

.pr17 {
    padding-right: 17px;
}

.pr19 {
    padding-right: 19px;
}

.pr20 {
    padding-right: 20px;
}

.pr25 {
    padding-right: 25px;
}

.pr30 {
    padding-right: 30px;
}

.pr35 {
    padding-right: 35px;
}

.pr40 {
    padding-right: 40px;
}

.pr45 {
    padding-right: 45px;
}

.pr50 {
    padding-right: 50px;
}

.pr55 {
    padding-right: 55px;
}

.pr60 {
    padding-right: 60px;
}

.pr65 {
    padding-right: 65px;
}

.pr70 {
    padding-right: 70px;
}

.pr80 {
    padding-right: 80px;
}

.pr90 {
    padding-right: 90px;
}

.pr95 {
    padding-right: 95px;
}

.pr100 {
    padding-right: 100px;
}

.pr110 {
    padding-right: 110px;
}

.pr115 {
    padding-right: 115px;
}

.pr120 {
    padding-right: 120px;
}

.pr130 {
    padding-right: 130px;
}

.pr140 {
    padding-right: 140px;
}

.lh120p {
    line-height: 120%;
}

.lh100 {
    line-height: 100px;
}

.lh110 {
    line-height: 110px;
}

.lh14 {
    line-height: 14px;
}

.lh18 {
    line-height: 18px;
}

.lh20 {
    line-height: 20px;
}

.lh24 {
    line-height: 24px;
}

.lh25 {
    line-height: 25px;
}

.lh30 {
    line-height: 30px;
}

.lh35 {
    line-height: 35px;
}

.lh40 {
    line-height: 40px;
}

.lh130 {
    line-height: 130px;
}


/* font
.fs_11 { font-size:0.688em; }
.fs_12 { font-size:0.750em; }
.fs_13 { font-size:0.813em; }
.fs_14 { font-size:0.875em; }
.fs_15 { font-size:0.938em; }
.fs_16 { font-size:1.000em; }
.fs_17 { font-size:1.063em; }
.fs_18 { font-size:1.125em; }
.fs_19 { font-size:1.188em; }
.fs_20 { font-size:1.250em; }

.fs_21 { font-size:1.313em; }
.fs_22 { font-size:1.375em; }
.fs_23 { font-size:1.438em; }
.fs_24 { font-size:1.500em; }
.fs_25 { font-size:1.563em; }
.fs_26 { font-size:1.625em; }
.fs_27 { font-size:1.688em; }
.fs_28 { font-size:1.750em; }
.fs_29 { font-size:1.813em; }
.fs_30 { font-size:1.875em; }
.fs_31 { font-size:1.938em; }
.fs_32 { font-size:2.000em; }
.fs_33 { font-size:2.063em; }
.fs_34 { font-size:2.125em; }
.fs_35 { font-size:2.188em; }
.fs_36 { font-size:2.250em; }
.fs_37 { font-size:2.313em; }
.fs_38 { font-size:2.375em; }
.fs_39 { font-size:2.438em; }
.fs_40 { font-size:2.500em; }

.fs_41 { font-size:2.563em; }
.fs_42 { font-size:2.625em; }
.fs_43 { font-size:2.688em; }
.fs_44 { font-size:2.750em; }
.fs_45 { font-size:2.813em; }
.fs_46 { font-size:2.875em; }
.fs_47 { font-size:2.938em; }
.fs_48 { font-size:3.000em; }
.fs_49 { font-size:3.063em; }
.fs_50 { font-size:3.125em; }
.fs_51 { font-size:3.188em; }
.fs_52 { font-size:3.250em; }
.fs_53 { font-size:3.313em; }
.fs_54 { font-size:3.375em; }
.fs_55 { font-size:3.438em; }
.fs_56 { font-size:3.500em; }
.fs_57 { font-size:3.563em; }
.fs_58 { font-size:3.625em; }
.fs_59 { font-size:3.688em; }
.fs_60 { font-size:3.750em; }
.fs_61 { font-size:3.813em; }
.fs_62 { font-size:3.875em; }
.fs_63 { font-size:3.938em; }
.fs_64 { font-size:4.000em; }
.fs_65 { font-size:4.063em; }
.fs_66 { font-size:4.125em; }
.fs_67 { font-size:4.188em; }
.fs_68 { font-size:4.250em; }
.fs_69 { font-size:4.313em; }
.fs_70 { font-size:4.375em; }
.fs_71 { font-size:4.438em; }
.fs_72 { font-size:4.500em; }
.fs_78 { font-size:4.875em; }
.fs_105 { font-size:6.563em; }
*/

.fs_11 {
    font-size: 0.688em;
}

.fs_12 {
    font-size: 0.750em;
}

.fs_13 {
    font-size: 0.813em;
}

.fs_14 {
    font-size: 0.875em;
}

.fs_15 {
    font-size: 0.938em;
}

.fs_16 {
    font-size: 1.000em;
}

.fs_17 {
    font-size: 1.063em;
}

.fs_18 {
    font-size: 1.125em;
}

.fs_19 {
    font-size: 1.188em;
}

.fs_20 {
    font-size: 1.250em;
}

.fs_21 {
    font-size: 1.313em;
}

.fs_22 {
    font-size: 1.375em;
}

.fs_23 {
    font-size: 1.438em;
}

.fs_24 {
    font-size: 1.500em;
}

.fs_25 {
    font-size: 1.563em;
}

.fs_26 {
    font-size: 1.625em;
}

.fs_27 {
    font-size: 1.688em;
}

.fs_28 {
    font-size: 1.750em;
}

.fs_29 {
    font-size: 1.813em;
}

.fs_30 {
    font-size: 1.875em;
}

.fs_31 {
    font-size: 1.938em;
}

.fs_32 {
    font-size: 2.000em;
}

.fs_33 {
    font-size: 2.063em;
}

.fs_34 {
    font-size: 2.125em;
}

.fs_35 {
    font-size: 2.188em;
}

.fs_36 {
    font-size: 2.250em;
}

.fs_37 {
    font-size: 2.313em;
}

.fs_38 {
    font-size: 2.375em;
}

.fs_39 {
    font-size: 2.438em;
}

.fs_40 {
    font-size: 2.500em;
}

.fs_41 {
    font-size: 2.563em;
}

.fs_42 {
    font-size: 2.625em;
}

.fs_43 {
    font-size: 2.688em;
}

.fs_44 {
    font-size: 2.750em;
}

.fs_45 {
    font-size: 2.813em;
}

.fs_46 {
    font-size: 2.875em;
}

.fs_47 {
    font-size: 2.938em;
}

.fs_48 {
    font-size: 3.000em;
}

.fs_49 {
    font-size: 3.063em;
}

.fs_50 {
    font-size: 3.125em;
}

.fs_51 {
    font-size: 3.188em;
}

.fs_52 {
    font-size: 3.250em;
}

.fs_53 {
    font-size: 3.313em;
}

.fs_54 {
    font-size: 3.375em;
}

.fs_55 {
    font-size: 3.438em;
}

.fs_56 {
    font-size: 3.500em;
}

.fs_57 {
    font-size: 3.563em;
}

.fs_58 {
    font-size: 3.625em;
}

.fs_59 {
    font-size: 3.688em;
}

.fs_60 {
    font-size: 3.750em;
}

.fs_61 {
    font-size: 3.813em;
}

.fs_62 {
    font-size: 3.875em;
}

.fs_63 {
    font-size: 3.938em;
}

.fs_64 {
    font-size: 4.000em;
}

.fs_65 {
    font-size: 4.063em;
}

.fs_66 {
    font-size: 4.125em;
}

.fs_67 {
    font-size: 4.188em;
}

.fs_68 {
    font-size: 4.250em;
}

.fs_69 {
    font-size: 4.313em;
}

.fs_70 {
    font-size: 4.375em;
}

.fs_71 {
    font-size: 4.438em;
}

.fs_72 {
    font-size: 4.500em;
}

.fs_78 {
    font-size: 4.875em;
}

.fs_100 {
    font-size: 6.250em;
}

.fs_105 {
    font-size: 6.563em;
}


.f_we0 {
    font-weight: 100;
}

.f_we1 {
    font-weight: 200;
}

.f_we2 {
    font-weight: 300;
}

.f_we3 {
    font-weight: 400;
}

.f_we4 {
    font-weight: 500;
}

.f_we5 {
    font-weight: 600;
}

.f_we6 {
    font-weight: 700;
}

.f_we7 {
    font-weight: 800;
}

.f_we8 {
    font-weight: 900;
}

.letterp1 {
    letter-spacing: 1px;
}

.letterp8 {
    letter-spacing: 8px;
}

.letterp14 {
    letter-spacing: 1.4px;
}

.letter12 {
    letter-spacing: 1.2px;
}

.letter18 {
    letter-spacing: 1.8px;
}

.letter16 {
    letter-spacing: 16px;
}

.letterm16 {
    letter-spacing: 1.6px;
}

.letter10 {
    letter-spacing: 10px;
}

.letter2 {
    letter-spacing: -2px;
}

.letter3 {
    letter-spacing: -3px;
}

.letter36 {
    letter-spacing: 3.6px;
}

.letterm36 {
    letter-spacing: -0.36px;
}

.letterm48 {
    letter-spacing: -0.48px;
}


/* width */


.we7 {
    width: 7%;
}

.we10 {
    width: 10%;
}

.we15 {
    width: 15%;
}

.we17 {
    width: 17%;
}

.we100 {
    width: 100%;
}

.we20 {
    width: 19.99%;
}

.we25 {
    width: 25%;
}

.we30 {
    width: 30%;
}

.we35 {
    width: 35%;
}

.we33 {
    width: 33%;
}

.we40 {
    width: 40%;
}

.we45 {
    width: 45%;
}

.we50 {
    width: 50%;
}

.we60 {
    width: 60%;
}

.we80 {
    width: 80%;
}

.we260 {
    width: 260px;
}

.we142 {
    width: 14.2%;
}


.we_20 {
    width: 20px;
}

.we_30 {
    width: 30px;
}

.we_44 {
    width: 44px;
}

.we_50 {
    width: 50px;
}

.we_60 {
    width: 60px;
}

.we_64 {
    width: 64px;
}

.we_70 {
    width: 70px;
}

.we_90 {
    width: 90px;
}

.we_100 {
    width: 100px;
}

.we_110 {
    width: 110px;
}

.we_120 {
    width: 120px;
}

.we_130 {
    width: 130px;
}

.we_140 {
    width: 140px;
}

.we_150 {
    width: 150px;
}

.we_170 {
    width: 170px;
}

.we_200 {
    width: 200px;
}

.we_220 {
    width: 220px;
}

.we_260 {
    width: 260px;
}

.we_230 {
    width: 230px;
}

.we_300 {
    width: 300px;
}

.we_350 {
    width: 350px;
}

.we_750 {
    width: 750px;
}

.min_w1 {
    min-width: 1200px;
}

/* height */
.min_h1 {
    min-height: 800px;
}

.he100p {
    height: 100%;
}

.he10 {
    height: 10px;
}

.he15 {
    height: 15px;
}

.he20 {
    height: 20px;
}

.he25 {
    height: 25px;
}

.he29 {
    height: 29px;
}

.he30 {
    height: 30px;
}

.he35 {
    height: 35px;
}

.he37 {
    height: 37px;
}

.he38 {
    height: 38px;
}

.he40 {
    height: 40px;
}

.he43 {
    height: 43px;
}

.he45 {
    height: 45px;
}

.he50 {
    height: 50px;
}

.he54 {
    height: 54px;
}

.he55 {
    height: 55px;
}

.he60 {
    height: 60px;
}

.he65 {
    height: 65px;
}

.he70 {
    height: 70px;
}

.he75 {
    height: 75px;
}

.he80 {
    height: 80px;
}

.he85 {
    height: 85px;
}

.he90 {
    height: 90px;
}

.he95 {
    height: 95px;
}

.he100 {
    height: 100px;
}

.he105 {
    height: 105px;
}

.he110 {
    height: 110px;
}

.he115 {
    height: 115px;
}

.he116 {
    height: 116px;
}

.he120 {
    height: 120px;
}

.he125 {
    height: 125px;
}

.he130 {
    height: 130px;
}

.he135 {
    height: 135px;
}

.he140 {
    height: 140px;
}

.he145 {
    height: 145px;
}

.he149 {
    height: 149px;
}

.he150 {
    height: 150px;
}

.he155 {
    height: 155px;
}

.he160 {
    height: 160px;
}

.he170 {
    height: 170px;
}

.he175 {
    height: 175px;
}

.he180 {
    height: 180px;
}

.he185 {
    height: 185px;
}

.he190 {
    height: 190px;
}

.he157 {
    height: 157px;
}

.he240 {
    height: 240px;
}

.he250 {
    height: 250px;
}

.he200 {
    height: 200px;
}

.he205 {
    height: 205px;
}

.he210 {
    height: 210px;
}

.he220 {
    height: 220px;
}

.he230 {
    height: 230px;
}

.he240 {
    height: 240px;
}

.he245 {
    height: 245px;
}

.he246 {
    height: 246px;
}

.he250 {
    height: 250px;
}

.he260 {
    height: 260px;
}

.he270 {
    height: 270px;
}

.he285 {
    height: 285px;
}

.he290 {
    height: 290px;
}

.he300 {
    height: 300px;
}

.he320 {
    height: 320px;
}

.he330 {
    height: 330px;
}

.he340 {
    height: 340px;
}

.he370 {
    height: 370px;
}

.he380 {
    height: 380px;
}

.he400 {
    height: 400px;
}

.he430 {
    height: 430px;
}

.he470 {
    height: 470px;
}

.he302 {
    height: 302px;
}

.he314 {
    height: 314px;
}

.he350 {
    height: 350px;
}

.he460 {
    height: 460px;
}

.he480 {
    height: 480px;
}

.he500 {
    height: 500px;
}

.he520 {
    height: 520px;
}

.he530 {
    height: 530px;
}

.he540 {
    height: 540px;
}

.he570 {
    height: 570px;
}

.he580 {
    height: 580px;
}

.he600 {
    height: 600px;
}

.he635 {
    height: 635px;
}

.he650 {
    height: 650px;
}

.he670 {
    height: 670px;
}

.he698 {
    height: 698px;
}

.he700 {
    height: 700px;
}

.he800 {
    height: 800px;
}

/*color*/
.fc00 {
    color: #fff;
}

.fc0 {
    color: #000;
}

.fc1 {
    color: #111;
}

.fc2 {
    color: #222;
}

.fc3 {
    color: #333;
}

.fc4 {
    color: #444;
}

.fc5 {
    color: #555;
}

.fc6 {
    color: #666;
}

.fc7 {
    color: #777;
}

.fc8 {
    color: #888;
}

.fc9 {
    color: #999;
}

.fc11 {
    color: #9d9d9d;
}

.fc22 {
    color: #ed2124;
}

.fc33 {
    color: #acacac;
}

.fc44 {
    color: #a2a2a2;
}

.fc55 {
    color: #03a9f4;
}

.fc66 {
    color: #ed7300;
}

.fc77 {
    color: #ff0000;
}

/*############################################ 초기설정끝 ###################################################*/

.tv_area {
    width: 1920px;
    height: 1080px;
}


/* form-control */
.form-control {
    display: block;
    width: 100%;
    line-height: 30px;
    background-color: #fff;
    background-image: none;
    border: solid 1px #cccccc;
    border-radius: 0px;
    padding: 15px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.form-control:focus {
    border: 1px solid #000;
}

.form-control:disabled {
    background: #fff;
    line-height: 100%;
}


input::placeholder {
    color: #666;
}


.letter1 {
    letter-spacing: -1px;
}

.filebox input[type="file"] {
    /* 파일 필드 숨기기 */
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

input[type=text]::-ms-clear {
    display: none;
}

/* 버튼 */
a.gitav_bt {
    width: 246px;
    padding: 5px 20px 5px 20px;
    border: 1px solid #000;
    text-align: center;
    line-height: 50px;
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    background: #000;
}

a:hover.gitav_bt {
    width: 246px;
    height: 59px;
    border: 1px solid #000;
    text-align: center;
    line-height: 50px;
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    background: #333;
}

.gitav_bt:disabled {
    width: 246px;
    height: 59px;
    border: 1px solid #000;
    text-align: center;
    line-height: 50px;
    font-size: 18px;
    font-weight: 500;
    color: #000;
    background: #fff;
}

.submit_bt1 {
    width: 246px;
    height: 59px;
    border: 1px solid #000;
    text-align: center;
    line-height: 50px;
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    background: #000;
}

.submit_bt1:disabled {
    width: 246px;
    height: 59px;
    border: 1px solid #000;
    text-align: center;
    line-height: 50px;
    font-size: 18px;
    font-weight: 500;
    color: #000;
    background: #fff;
}



/* @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ START @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ */
.container {
    max-width: 1666px;
    padding-left: 20px;
    padding-right: 20px;
}

.container-fluid {
    padding: 0;
    width: 100%;
}

#con {
    position: absolute;
    z-index: -1000;
    top: 0;
    background-color: #111;
    overflow: hidden;
}

.position_fi_top {
    position: fixed;
    top: 0;
    z-index: 10;
}

.position_fi_bottom {
    position: fixed;
    bottom: 0;
    z-index: 10;
}


.title_icon {
    width: 83px;
    height: 2px;
    background: #000;
    position: absolute;
    top: 7px;
    margin-left: 20px;
}

.sl_l_bt {
    position: absolute;
    left: 5%;
    top: 50%;
    margin-top: -28px;
}

.sl_r_bt {
    position: absolute;
    right: 5%;
    top: 50%;
    margin-top: -28px;
}

.sl_l_bt:hover {
    content: url("../images/common/sl_l_h.png");
}

.sl_r_bt:hover {
    content: url("../images/common/sl_r_h.png");
}

.ca_menu_ho {
    border-bottom: 2px solid #000;
    color: #000;
    padding-bottom: 2px;
}

.ca_menu_no {
    border-bottom: 0;
    color: #666;
    padding-bottom: 2px;
}

.im_area_js:hover .images_bg {
    display: block;
}

.im_area_js:hover .images_hover {
    display: block;
}

.p_r_bt:hover .p_r {
    content: url("../images/common/r_bt_h.png");
}

.p_r_bt:hover {
    color: #000;
}

.p_l_bt:hover .p_l {
    content: url("../images/common/l_bt_h.png");
}

.p_l_bt:hover {
    color: #000;
}

.page_bg1 {
    background: url("../images/page/page_img1.png") no-repeat;
    background-size: cover;
    background-position: center;
}

.page_bg2 {
    background: url("../images/page/page_img3.png") no-repeat;
    background-size: cover;
    background-position: center;
}

.cover_bg {
    background-size: cover;
    background-position: center;
}

.images_bg {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
}

.images_hover {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    margin-top: -19px;
    margin-left: -55px;
    width: 110px;
    height: 38px;
    border-radius: 25px;
    border: solid 2px #ffffff;
    background-color: rgba(255, 255, 255, 0.17);
    text-align: center;
    color: #fff;
    font-size: 14px;
    letter-spacing: 1.4px;
    line-height: 18px;
    padding-top: 7px;
}

.prl5 {
    padding-left: 5px;
    padding-right: 5px;
}

.prl20 {
    padding-left: 20px;
    padding-right: 20px;
}


.bor_b1 {
    border-bottom: 2px solid #000;
}

.bor_b2 {
    border-bottom: 2px solid #ccc;
}


.footer_a a:link {
    color: #999;
}

.footer_a a:hover {
    color: #999;
}

.footer_a a:visited {
    color: #999;
}

.ca_menu_link_style a:link {
    color: #666;
}

.ca_menu_link_style a:hover {
    text-decoration: underline;
    color: #000;
}

.ca_menu_link_style a:visited {
    color: #666;
}


.menu_box {
    position: fixed;
    z-index: 100;
    background: #111;
    height: 100%;
}

a.menu_default {
    border-bottom: 5px solid transparent;
    color: #fff;
}

a.menu_vi {
    border-bottom: 5px solid transparent;
    color: #fff;
}

a.menu_no {
    border-bottom: 5px solid transparent;
    color: #666;
}

a.menu_hover {
    border-bottom: 5px solid #fff;
    color: #fff;
}

a.gnb_sub_link:link,
a.gnb_sub_link:hover,
a.gnb_sub_link:visited {
    color: #fff;
}

a.sub_link:link,
a.sub_link:hover,
a.sub_link:visited {
    color: #111;
}



.bg_color0 {
    background: #fff;
}

.bg_color1 {
    background: #f8f8f8;
}




.ck_type1 input[type=checkbox] {
    display: none;
}

.ck_type1 input[type=checkbox]+label {
    width: 20px;
    height: 20px;
    border: solid 2px #707070;
    background-color: #ffffff;
}

.ck_type1 input[type=checkbox]:checked+label {
    width: 20px;
    height: 20px;
    border: solid 2px #000;
    background-color: #000;
}

.ck_type1 input[type=checkbox]+label+label {
    display: inline;
    font-size: 18px;
    letter-spacing: -0.36px;
    font-weight: 500;
    color: #666;
    position: relative;
    top: -5px;
}

.ck_type1 input[type=checkbox]:checked+label+label {
    display: inline;
    font-size: 18px;
    letter-spacing: -0.36px;
    font-weight: 500;
    color: #000;
    position: relative;
    top: -5px;
}


/*원 radio style*/
.radio_style1 input[type=radio] {
    display: none;
}

.radio_style1 label {
    color: #111;
    cursor: pointer;
}

.radio_style1 input[type=radio]+label {
    cursor: pointer;
    width: 16px;
    height: 16px;
}

.radio_style1 input[type=radio]:checked+label {
    cursor: pointer;
    width: 16px;
    height: 16px;
}

.radio_style1 input[type=radio]:disabled+label {
    cursor: pointer;
    width: 16px;
    height: 16px;
    background: #e9e9e9;
}

.radio_style1 input[type=radio]+label+label {
    display: inline;
    position: relative;
    top: -5px;
}

.radio_style1 input[type=radio]:checked+label+label {
    display: inline;
}

.radio_style1 input[type=radio]:disabled+label+label {
    display: inline;
}


.in_box {
    background: #fff;
    border-radius: 10px;
    /*
 -webkit-box-shadow: 0 0 0 4px white, 0 4px 4px grey;
  -moz-box-shadow: 0 0 0 4px white, 0 4px 4px grey;
  box-shadow: 0 0 0 4px white, 0 4px 4px grey;
  */
    box-shadow: 0px 15px 10px -15px grey;
}




/*select box style*/

select.select_style {
    background: url("../images/common/sel_n.png") no-repeat, #fff;
    /* 네이티브 화살표 대체 */
    border: 1px solid #ccc;
    color: #000;
    padding: 15px;
    line-height: 25px;
    background-position: right 15px top 50%;
    -webkit-appearance: none;
    /* 네이티브 외형 감추기 */
    -moz-appearance: none;
    appearance: none;
    outline: none;
}

select.select_style:hover {
    background: url("../images/common/sel_d.png") no-repeat, #fff;
    /* 네이티브 화살표 대체 */
    border: 1px solid #000;
    color: #000;
    padding: 15px;
    line-height: 25px;
    background-position: right 15px top 50%;
    -webkit-appearance: none;
    /* 네이티브 외형 감추기 */
    -moz-appearance: none;
    appearance: none;
    outline: none;
}

select.select_style:focus {
    background: url("../images/common/sel_top.png") no-repeat, #fff;
    /* 네이티브 화살표 대체 */
    border: 1px solid #000;
    color: #000;
    padding: 15px;
    line-height: 25px;
    background-position: right 15px top 50%;
    -webkit-appearance: none;
    /* 네이티브 외형 감추기 */
    -moz-appearance: none;
    appearance: none;
    outline: none;
}



.input_no {
    outline: none
}

select::-ms-expand {
    display: none;
}



.right_menu {
    position: fixed;
    top: 0;
    left: 100%;
    width: 80%;
    height: 100%;
    z-index: 100000000;
    background: #fff;
}

.right_menu_bg {
    position: fixed;
    top: 0;
    right: -100000px;
    background: #000;
    opacity: 0.7;
    width: 100%;
    height: 100%;
    z-index: 10000000;
}

.menu_cl_bt {
    position: absolute;
    top: 70px;
    left: -80px;
}


/*페이지화*/
.page_num_style>span {
    padding-top: 8px;
    border: 1px solid #eeeeee;
    display: inline-block;
    width: 40px;
    height: 40px;
    cursor: pointer;
    margin: 0 3px;
}




.fs_14_18 {
    font-size: 18px;
}

.fs_100_50 {
    font-size: 50px;
    line-height: 60px;
}

.pl95_20 {
    padding-left: 95px;
}

.he820_he300 {
    height: 820px;
}

.prl15_5 {
    padding-left: 15px;
    padding-right: 15px;
}

.mb50_30 {
    margin-bottom: 50px;
}

.mt50_30 {
    margin-top: 50px;
}

.we_600_230 {
    width: 600px;
}

.he384_246 {
    height: 384px;
}

.fs_80_60_24 {
    font-size: 80px;
}

.he870_500_300 {
    height: 870px;
}

.he700_500_300 {
    height: 700px;
}

.fs_24_18 {
    font-size: 24px;
}

.left_gnb {
    position: static;
}

.he437_250 {
    height: 437px;
}

.pl20_0 {
    padding-left: 20px;
}

.prl25_10 {
    padding-left: 25px;
    padding-right: 25px;
}

.prl20_5 {
    padding-left: 20px;
    padding-right: 20px;
}

.mb20_10 {
    margin-bottom: 20px;
}

.top_bt_css {
    z-index: 10;
}

.he500_400_300 {
    height: 500px;
}

.he130_70 {
    height: 130px;
}

.lh130_70 {
    line-height: 130px;
}

/* Extra Small */

@media (max-width: 575.98px) {
    .container {
        padding-left: 10px;
        padding-right: 10px;
    }

    .pl95_20 {
        padding-left: 20px;
    }

    .fs_100_50 {
        font-size: 40px;
        line-height: 50px;
    }

    .he820_he300 {
        height: 300px;
    }

    .prl15_5 {
        padding-left: 5px;
        padding-right: 5px;
    }

    .mb50_30 {
        margin-bottom: 30px;
    }

    .mt50_30 {
        margin-top: 30px;
    }

    .we_600_230 {
        width: 230px;
    }

    .he384_246 {
        height: 246px;
    }

    .fs_80_50_30_24 {
        font-size: 24px;
    }

    .he870_500_300 {
        height: 300px;
    }

    .he700_500_300 {
        height: 300px;
    }

    .fs_24_18 {
        font-size: 18px;
    }

    .left_gnb {
        position: static;
    }

    .he437_250 {
        height: 250px;
    }

    .pl20_0 {
        padding-left: 0px;
    }

    .prl25_10 {
        padding-left: 10px;
        padding-right: 10px;
    }

    .mb20_10 {
        margin-bottom: 10px;
    }

    .prl20_5 {
        padding-left: 5px;
        padding-right: 5px;
    }

    .top_bt_css {
        position: fixed;
        right: 10px;
        margin-right: 0;
        bottom: 50px;
    }

    .fs_100_70_50 {
        font-size: 40px;
        line-height: 45px;
    }

    .he500_400_300 {
        height: 300px;
    }

    .fs_40_30_20 {
        font-size: 20px;
    }

    .mt220_180_135 {
        margin-top: 135px;
    }

    .he130_70 {
        height: 70px;
    }

    .lh130_70 {
        line-height: 70px;
    }
}

/* Small */
@media (min-width: 576px) and (max-width: 767.98px) {
    .container {
        padding-left: 10px;
        padding-right: 10px;
    }

    .pl95_20 {
        padding-left: 20px;
    }

    .fs_100_50 {
        font-size: 40px;
        line-height: 50px;
    }

    .he820_he300 {
        height: 300px;
    }

    .prl15_5 {
        padding-left: 5px;
        padding-right: 5px;
    }

    .mb50_30 {
        margin-bottom: 30px;
    }

    .mt50_30 {
        margin-top: 30px;
    }

    .we_600_230 {
        width: 230px;
    }

    .he384_246 {
        height: 246px;
    }

    .fs_80_50_30_24 {
        font-size: 24px;
    }

    .he870_500_300 {
        height: 300px;
    }

    .he700_500_300 {
        height: 300px;
    }

    .fs_24_18 {
        font-size: 18px;
    }

    .left_gnb {
        position: static;
    }

    .he437_250 {
        height: 250px;
    }

    .pl20_0 {
        padding-left: 0px;
    }

    .prl25_10 {
        padding-left: 10px;
        padding-right: 10px;
    }

    .mb20_10 {
        margin-bottom: 10px;
    }

    .prl20_5 {
        padding-left: 5px;
        padding-right: 5px;
    }

    .top_bt_css {
        position: fixed;
        right: 10px;
        margin-right: 0;
        bottom: 50px;
    }

    .fs_100_70_50 {
        font-size: 40px;
        line-height: 45px;
    }

    .he500_400_300 {
        height: 300px;
    }

    .fs_40_30_20 {
        font-size: 20px;
    }

    .mt220_180_135 {
        margin-top: 135px;
    }

    .he130_70 {
        height: 70px;
    }

    .lh130_70 {
        line-height: 70px;
    }
}



/* Small devices (tablets or large size smart phone of size 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
    .fs_80_50_30_24 {
        font-size: 30px;
    }

    .he870_500_300 {
        height: 300px;
    }

    .he700_500_300 {
        height: 300px;
    }

    .left_gnb {
        position: static;
    }

    .top_bt_css {
        position: fixed;
        right: 50%;
        margin-right: -350px;
        bottom: 50px;
    }

    .fs_100_70_50 {
        font-size: 40px;
        line-height: 45px;
    }

    .he820_he300 {
        height: 300px;
    }

    .he500_400_300 {
        height: 300px;
    }

    .fs_40_30_20 {
        font-size: 20px;
    }

    .mt220_180_135 {
        margin-top: 135px;
    }
}

/* Medium devices (desktops, laptops of size 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {

    /*.container { width:750px;  }*/
    .fs_80_50_30_24 {
        font-size: 80px;
    }

    .he870_500_300 {
        height: 500px;
    }

    .fs_100_50 {
        font-size: 100px;
        line-height: 110px;
    }

    .he700_500_300 {
        height: 500px;
    }

    .left_gnb {
        position: fixed;
    }

    .top_bt_css {
        position: fixed;
        right: 50%;
        margin-right: -465px;
        bottom: 50px;
    }

    .fs_100_70_50 {
        font-size: 70px;
        line-height: 75px;
    }

    .he820_he300 {
        height: 820px;
    }

    .he500_400_300 {
        height: 400px;
    }

    .fs_40_30_20 {
        font-size: 30px;
    }

    .mt220_180_135 {
        margin-top: 180px;
    }
}

/* Large devices (large desktops, screens of size 1200px and up) */
@media (min-width: 1200px) {
    .fs_80_50_30_24 {
        font-size: 80px;
    }

    .he870_500_300 {
        height: 500px;
    }

    .fs_100_50 {
        font-size: 100px;
        line-height: 110px;
    }

    .he700_500_300 {
        height: 500px;
    }

    .left_gnb {
        position: fixed;
    }

    .top_bt_css {
        position: fixed;
        right: 50%;
        margin-right: -465px;
        bottom: 50px;
    }

    .fs_100_70_50 {
        font-size: 100px;
        line-height: 105px;
    }

    .he820_he300 {
        height: 820px;
    }

    .he500_400_300 {
        height: 500px;
    }

    .fs_40_30_20 {
        font-size: 40px;
    }

    .mt220_180_135 {
        margin-top: 220px;
    }
}


/* Large devices (large desktops, screens of size 1200px and up)
@media (min-width: 1280px) {
.fs_14_18{font-size:14px;}
.fs_100_50 {font-size:100px; line-height:110px; }
.fs_80_50_30_24 {font-size:80px;}
.left_gnb { position:static; }
.top_bt_css {position:fixed; right:50%; margin-right:-465px; bottom:50px;}
} */



@media (min-width: 1690px) {
    .container {
        width: 1666px;
    }

    .he870_500_300 {
        height: 870px;
    }

    .he700_500_300 {
        height: 700px;
    }

    .left_gnb {
        position: fixed;
        max-width: 500px;
    }

    .top_bt_css {
        position: fixed;
        right: 50%;
        margin-right: -811px;
        bottom: 50px;
    }

    .fs_100_70_50 {
        font-size: 100px;
        line-height: 105px;
    }

    .he500_400_300 {
        height: 500px;
    }

    .fs_40_30_20 {
        font-size: 40px;
    }

    .mt220_180_135 {
        margin-top: 220px;
    }
}













@media (min-width: 993px) and (max-width: 1200px) {
    .w_hi {
        display: none;
    }
}

.swal-overlay,
.swal-modal {
    z-index: 100000000000;
}


/* ===== */
.main_body {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
}







.my-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
}

.carousel-caption {
    top: 50%;
    transform: translateY(-50%);
    bottom: initial;
}