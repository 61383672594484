.condiv {
    position: relative;
    display: flex;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}


.text-1,
.text-2,
.text-3 {
    position: relative;
    opacity: 0;
    transform: translateX(-100%);
    cursor: default;
}

.text-4 {
    opacity: 0;
}